import { PaymentMethod } from '@hah/enums';
import * as Sentry from '@sentry/browser';
import { FormikHelpers } from 'formik';
import React, { MutableRefObject } from 'react';
import { BookOrderValidationModel } from '../validationSchema';
import * as api from '@hah/typewriter/controllers/CheckoutController';
import { PickSetState } from '@hah/utils';
import { hpProblemJsonFetchError } from '../../custom-types/http-utils';
import { Dropin } from '../../custom-types/braintree-web-drop-in-custom';

const initialState: models.CheckoutPaymentViewModel = {
    billingContact: {
        firstName: '',
        lastName: '',
        email: '',
        primaryPhone: '',
        alternatePhone: '',
    },
    billingContactSameAsJobContact: true,
    paymentMethod: PaymentMethod.Unknown,
    authOrLinkedJobsKey: { customerAuthKey: '', linkedJobsKey: '', quoteKey: '' },
    billingAddress: { street: '', city: '', state: '', zip: '' },
    upgradeInsurance: false,
    hhPhoneNumber: '',
    braintreeDeviceData: '',
    jobNotes: [],
    paymentMethodNonce: '',
    payNow: false,
};

export function useBookOrderHandler(pageVm: models.CheckoutPaymentStepReactViewModel) {
    const [viewModel, setViewModel] = React.useState(pageVm);
    const [model, setModel] = React.useState<models.CheckoutPaymentViewModel>(initialState);
    const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);


    const handleChangeVm = React.useCallback(
        (change: PickSetState<models.CheckoutPaymentStepReactViewModel>) => {
            setViewModel((prev) => ({ ...prev, ...change }));
        },
        [setViewModel],
    );

    const handleChange = React.useCallback(
        (change: PickSetState<models.CheckoutPaymentViewModel>) => {
            setModel((prev) => ({ ...prev, ...change }));
        },
        [setModel],
    );

    const handleSubmit = async (values: BookOrderValidationModel, helpers: FormikHelpers<BookOrderValidationModel>, submitModel: models.CheckoutPaymentViewModel, paymentNonce: MutableRefObject<string>, dropinInstance: MutableRefObject<Dropin | undefined>) => {
        if (!paymentNonce.current) {
            globalSitewide.toastError(null, 'Please select a valid payment method.');
            return;
        }

        const authTag = {
            customerAuthKey: pageVm.authToken.customerAuthKey,
            linkedJobsKey: pageVm.authToken.linkedJobsKey,
            'grouping.Checkout': true,
        };

        if (isFormSubmitting) {
            // we're already submitting. let's not cause double submits...
            // log sentry warning but dont say anything to user
            Sentry?.captureEvent({
                level: 'warning',
                tags: authTag,
                message: 'Form is already submitting, ignoring submit request.',
                extra: {
                    model: JSON.stringify(submitModel),
                },
            });
            return;
        }

        setIsFormSubmitting(true);
        globalSitewide.blockPage('Submitting and Confirming Order...');

        submitModel.paymentMethodNonce = paymentNonce.current;
        submitModel.authOrLinkedJobsKey = pageVm.authToken;
        submitModel.hhPhoneNumber = pageVm.hhPhoneNumber;
        submitModel.billingContact = (submitModel.billingContactSameAsJobContact ? undefined : values.billingContact) as any;
        submitModel.billingAddress = (submitModel.paymentMethod == PaymentMethod.CreditCard ? values.paymentInfo.billingAddress : undefined) as any;

        try {
            Sentry?.addBreadcrumb({
                category: 'book-order',
                type: 'info',
                message: 'Attempting to bookOrder',
            });
            const result = await api.checkout(submitModel);

            if (result.result && result.result.redirectUrl) {
                window.location.href = result.result.redirectUrl;
                return;
            }

            if (!result.success) {
                // unsuccesful results will be logged in the api
                Sentry?.captureEvent({
                    level: 'warning',
                    tags: authTag,
                    message: 'Unsuccessful booking quote.',
                    extra: {
                        model: JSON.stringify(submitModel),
                        result: JSON.stringify(result),
                    },
                });

                dropinInstance.current!.clearSelectedPaymentMethod();

                if (result.errors.length > 0) {
                    setApiFieldErrors(result.errors, helpers);
                    const toastMessage = 'There was one or more validation errors with your form - please take a look and try submitting again.';
                    globalSitewide.toastError(null, toastMessage, true);
                } else {
                    globalSitewide.toastError(null, result.message, true);
                }
            }
        } catch (error: any) {
            dropinInstance.current!.clearSelectedPaymentMethod();
            let apiError = false;
            if (error.name == hpProblemJsonFetchError.Name) {
                const castedErr = error as hpProblemJsonFetchError;
                apiError = true;
                if (helpers) {
                    setApiFieldErrors(castedErr.webApiProblem.errors, helpers);
                }
                const toastMessage = 'There was one or more validation errors with your form - please take a look and try submitting again.';
                globalSitewide.toastError(null, toastMessage, true);
            } else {
                const toastMessage = `An unexpected error has occurred. We have notified our team and do apologize for any inconvenience. Please call us at ${pageVm.hhPhoneNumber} and we would be happy to assist you.`;
                globalSitewide.toastError(null, toastMessage, true);
            }

            Sentry?.captureEvent({
                level: apiError ? 'warning' : 'error',
                tags: authTag,
                message: `bookOrder returned non-success: ${error.message}`,
                extra: {
                    error,
                    model: JSON.stringify(submitModel),
                },
            });
        }

        setIsFormSubmitting(false);
        globalSitewide.unblockPage();
    };

    return { handleSubmit, isFormSubmitting, viewModel, model, handleChange, handleChangeVm };
}

function setApiFieldErrors(errors: any, formikHelpers: FormikHelpers<any>) {
    Object.entries(errors).forEach(([key, value]: [string, any]) => {
        // we use PastelCase in .net and camelCase in JS... so...
        const properKeys = key.split('.');
        const camelKeys = properKeys.map((k) => {
            return k.charAt(0).toLowerCase() + k.slice(1);
        });
        let camel = camelKeys.join('.');
        if (camel.indexOf('billingAddress') >= 0) {
            camel = 'paymentInfo.' + camel;
        }
        formikHelpers.setFieldTouched(camel, true, false);
        formikHelpers.setFieldError(camel, value);
    });
}
