export const LogoSpinner = () => {
    return (
        <div className="loading-container">
            <svg className="loading-svg" width="100" height="100" viewBox="0 0 143.9 143.9" xmlns="http://www.w3.org/2000/svg">
                <path
                    className="loading-logo"
                    d="M73.7 143.6l.3-.1c.2-.1.3-.2.5-.3.3-.2.5-.4.7-.6l67.3-67.3c1.8-1.8 1.8-4.7 0-6.5l-22.4-22.4-22.4-22.6L75.2 1.4c-.2-.2-.5-.4-.7-.6-.2-.1-.3-.2-.5-.3l-.3-.2c-.2-.1-.4-.1-.6-.2-.1 0-.2-.1-.2-.1-.6-.1-1.2-.1-1.8 0-.1 0-.1 0-.2.1-.2.1-.4.1-.7.2l-.3.1c-.2.1-.4.2-.5.3-.3.2-.5.4-.7.6L1.4 68.7c-1.8 1.8-1.8 4.7 0 6.5l67.3 67.3c.2.2.5.4.7.6.2.1.4.2.5.3l.3.1c.2.1.4.1.7.2l.2.1c.6.1 1.2.1 1.8 0l.2-.1c.2 0 .4 0 .6-.1zM95.2 34.4l14.4 14.4c.4.4.4 1.1 0 1.5L95.2 64.7c-.4.4-1.1.4-1.5 0L79.3 50.3c-.4-.4-.4-1.1 0-1.5l14.4-14.4c.4-.4 1-.4 1.5 0zM72.7 11.9l14.4 14.4c.4.4.4 1.1 0 1.5L68.7 46.2c-1.8 1.8-1.8 4.7 0 6.5l22.4 22.4c1.8 1.8 4.7 1.8 6.5 0L116 56.7c.4-.4 1.1-.4 1.5 0L132 71.2c.4.4.4 1.1 0 1.5L72.7 132c-.4.4-1.1.4-1.5 0l-14.4-14.4c-.4-.4-.4-1.1 0-1.5l18.4-18.4c1.8-1.8 1.8-4.7 0-6.5L52.8 68.7c-1.8-1.8-4.7-1.8-6.5 0L27.8 87.1c-.4.4-1.1.4-1.5 0L11.9 72.7c-.4-.4-.4-1.1 0-1.5l18.4-18.4 40.9-40.9c.4-.4 1.1-.4 1.5 0zm-24 97.7L34.4 95.2c-.4-.4-.4-1.1 0-1.5l14.4-14.4c.4-.4 1.1-.4 1.5 0l14.4 14.4c.4.4.4 1.1 0 1.5l-14.4 14.4c-.4.4-1.1.4-1.6 0 .1 0 .1 0 0 0z"
                    fill="transparent"
                />
            </svg>
        </div>
    );
};
